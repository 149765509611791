<template>
  <div class="wrapper">
    <v-account-header @mobileActive="mobileActive"></v-account-header>

    <router-view />

    <v-footer></v-footer>
  </div>
</template>

<script>
export default {
  name: 'AdminPage',
  data() {
    return {
      mobileStatus: false,
    }
  },
  methods: {
    mobileActive(status) {
      this.mobileStatus = status
    },
  },
}
</script>

<style>
@import './../../assets/css/vue-multiselect.min.css';
@import './../../assets/css/v-tooltip.css';
@import './../../assets/css/vuetify.css';

@import './../../assets/css/bootstrap.min.css';
@import './../../assets/css/swiper.min.css';

@import './../../assets/css/style.css';
@import './../../assets/css/style-admin.css';
@import './../../assets/css/media.css';
@import './../../assets/css/media-admin.css';
</style>
